import { Notifications } from "@/lib/graphql/notifications/Notifications.ts";

export class NotificationsComponent {
    async saveNotification(apollo, notification) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!notification) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benachrichtigung gefunden.",
                };
            }
            const savedNotification = await apollo
                .mutate({
                    mutation: Notifications.Mutations.SaveNotification,
                    fetchPolicy: "no-cache",
                    variables: {
                        notification: notification,
                    },
                })
                .then(({ data }) => data?.saveNotification)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return savedNotification;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
