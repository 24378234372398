import { render, staticRenderFns } from "./SalesCountWidget.vue?vue&type=template&id=4e585687&scoped=true&lang=pug"
import script from "./SalesCountWidget.vue?vue&type=script&lang=js"
export * from "./SalesCountWidget.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e585687",
  null
  
)

export default component.exports