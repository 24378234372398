export const PermissionsEnum = {
    Dashboard: 1,
    Products: 2,
    Vouchers: 3,
    Sales: 4,
    Account: 5,
    BaseData: 6,
    Settlements: 7,
    Contracts: 8,
    DataSheet: 9,
    UserManagement: 10,
    Security: 11,
    touriBook: 12,
    Notifications: 13,
    EmailSettings: 14,
    Documents: 18,
    ExclusionPeriods: 22,
};
