<template lang="pug">
tr
  td {{ notification.Id }}
  td {{ notification.Title }}
  td
    span(v-if="host") {{ host.Name }}
  td {{ notification.CreatedAt | formatDateTime }}
  td
    span(v-if="user") {{ user.FirstName}} {{ user.LastName }}
  td {{ notification.SeenAt | formatDateTime }}
  td
    router-link.button.button-primary.button-tdays(:to="{ path: `/account/notifications/detail/${notification.Id}` }" tag="button")
      | {{ $t("constants.buttons.show") }}

</template>

<script>
import { HostsComponent } from "@/lib/components/hosts/HostsComponent";
import UsersComponent from "@/lib/components/users/UsersComponent";

export default {
    name: "NotificationDetails",
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            host: undefined,
            user: undefined,
        };
    },
    async mounted() {
        try {
            this.loadHost();
            this.loadUser();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadHost() {
            try {
                const hostsComponent = new HostsComponent();
                const host = await hostsComponent.getHost(
                    this.$apollo,
                    this.notification.HostId
                );
                if (!host) {
                    return;
                }
                this.host = host;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadUser() {
            try {
                const usersComponent = new UsersComponent();
                const user = await usersComponent.getUser(
                    this.$apollo,
                    this.notification.SeenBy
                );
                if (!user) {
                    return;
                }
                this.user = user;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
