<template lang="pug">
  #login
    .row.mt-5
      .col-12.text-center
        h2 {{$t("login.headline")}}
    .row
      .col-sm-2.col-lg-3
      .col-12.col-sm-8.col-lg-6
        .form-group
          form(@submit.prevent="login")
          label.form-label {{$t("login.form.email")}}
          input.form-control.custom-control(v-model="email" :placeholder="this.$t('login.form.email')")
        .form-group
          form(@submit.prevent="login")
          label.form-label {{$t("login.form.password")}}
          input.form-control.custom-control(v-model="pass" :placeholder="this.$t('login.form.password')" type="password" v-on:keyup.enter="login")

        .form-group
          button.button.button-primary.button-tdays.w-100(type="submit" @click="login")
            | {{$t("login.button.login")}}

        a(target="_blank" href="/reset-password")
          p {{$t("login.forgotPassword")}}

    footer.mt-auto.py-3
      .container
        .text-muted.text-center
          | &copy; {{this.moment().year()}} touriDat GmbH & Co. KG
          br
          | Version: {{version}}


</template>

<script>
import EventBus from "../views/event-bus";
import { version } from "../../package.json";
import moment from "moment";
import { EmailValidatorComponent } from "../lib/components/emailValidator/EmailValidatorComponent";
import { Login } from "@/lib/graphql/login/Login.ts";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Login",
    data() {
        return {
            email: "",
            pass: "",
            guid: this.$router.currentRoute.query.guid,
            userGuid: this.$router.currentRoute.query.user,

            error: false,
            moment: moment,
            version: version,
        };
    },
    async mounted() {
        try {
            if (this.guid && this.userGuid) {
                await this._login();
            }
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async login() {
            try {
                if (this.email.length == 0) {
                    return this.$alert(
                        this.$t("login.errorMessages.e7").toString(),
                        this.$t("constants.messages.errorTitle").toString()
                    );
                }
                const emailValidatorComponent = new EmailValidatorComponent();
                if (!emailValidatorComponent.validateEmail(this.email)) {
                    return this.$alert(
                        this.$t("login.errorMessages.e8").toString(),
                        this.$t("constants.messages.errorTitle").toString()
                    );
                }
                if (!this.pass) {
                    return this.$alert(
                        this.$t("login.errorMessages.e8").toString(),
                        this.$t("constants.messages.errorTitle").toString()
                    );
                }
                await this._login();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async _login() {
            try {
                EventBus.$emit("changeLoadingState", true);
                const loginDashboardUser = await this.$apollo
                    .mutate({
                        mutation: Login.Mutations.LoginDashboardUser,
                        fetchPolicy: "no-cache",
                        variables: {
                            email: this.email.toLowerCase().trim(),
                            password: this.pass,
                            guid: this.guid,
                            userGuid: this.userGuid,
                        },
                    })
                    .then(({ data }) => data?.loginDashboardUser)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                EventBus.$emit("changeLoadingState", false);
                if (!loginDashboardUser) {
                    return this.$alert(
                        "Der Benutzer konnte nicht eingeloggt werden."
                    );
                }
                if (!loginDashboardUser.Authenticated) {
                    if (loginDashboardUser.Message) {
                        return this.$alert(loginDashboardUser.Message);
                    }
                }
                this.$session.start();
                this.$session.set("userId", loginDashboardUser.UserId);
                this.$session.set(
                    "validTill",
                    moment(new Date()).add(12, "hours").toDate()
                );
                EventBus.$emit("updatePermissions");
                const currentRoute = this.$router.currentRoute;
                if (currentRoute.query.redirect) {
                    return await this.$router.push({
                        path: currentRoute.query.redirect,
                    });
                }
                await this.$router.push({ name: "Home" });
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>
