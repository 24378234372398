const {
    SpecificExclusionPeriods,
} = require("@/lib/graphql/specificExclusionPeriods/SpecificExclusionPeriods.ts");
const moment = require("moment");

class SpecificExclusionPeriodsComponent {
    async getSpecificExclusionPeriods(
        apollo,
        productId,
        productDraftId,
        hostId
    ) {
        try {
            if (!apollo || (!productId && !productDraftId && !hostId)) {
                return undefined;
            }
            const specificExclusionPeriods = await apollo
                .query({
                    query: SpecificExclusionPeriods.Queries
                        .SpecificExclusionPeriods,
                    fetchPolicy: "no-cache",
                    variables: {
                        productId: productId,
                        productDraftId: productDraftId,
                        hostId: hostId,
                    },
                })
                .then(({ data }) => data?.specificExclusionPeriods)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return specificExclusionPeriods;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveSpecificExclusionPeriod(apollo, specificExclusionPeriod) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!specificExclusionPeriod) {
                return {
                    IsSuccess: false,
                    Message: "Keine Ausschlusszeit gefunden.",
                };
            }
            if (!specificExclusionPeriod.HostId) {
                return {
                    IsSuccess: false,
                    Message: "",
                };
            }
            if (!specificExclusionPeriod.CreatedBy) {
                return {
                    IsSuccess: false,
                    Message: "Kein Ersteller gefunden.",
                };
            }
            if (!specificExclusionPeriod.Description) {
                return {
                    IsSuccess: false,
                    Message: "Bitte vergeben Sie eine Beschreibung.",
                };
            }
            if (!specificExclusionPeriod.FromDate) {
                return {
                    IsSuccess: false,
                    Message: "Bitte vergeben Sie ein Startdatum.",
                };
            }
            if (!moment(specificExclusionPeriod.FromDate).isValid()) {
                return {
                    IsSuccess: false,
                    Message: "Bitte vergeben Sie ein gültiges Startdatum.",
                };
            }
            if (!specificExclusionPeriod.ToDate) {
                return {
                    IsSuccess: false,
                    Message: "Bitte vergeben Sie ein Enddatum.",
                };
            }
            if (!moment(specificExclusionPeriod.ToDate).isValid()) {
                return {
                    IsSuccess: false,
                    Message: "Bitte vergeben Sie ein Enddatum.",
                };
            }
            if (
                moment(specificExclusionPeriod.ToDate).isBefore(
                    specificExclusionPeriod.FromDate
                )
            ) {
                return {
                    IsSuccess: false,
                    Message: "Bitte geben Sie eine gültige Datenspanne an.",
                };
            }
            const savedSpecificExlcusionPeriod = await apollo
                .mutate({
                    mutation:
                        SpecificExclusionPeriods.Mutations
                            .SaveSpecificExclusionPeriod,
                    fetchPolicy: "no-cache",
                    variables: {
                        specificExclusionPeriod: specificExclusionPeriod,
                    },
                })
                .then(({ data }) => data?.saveSpecificExclusionPeriod)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return savedSpecificExlcusionPeriod;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async deleteSpecificExclusionPeriod(apollo, id) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!id) {
                return {
                    IsSuccess: false,
                    Message: "Keine Ausschlusszeit gefunden.",
                };
            }
            const deletedSpecificExlcusionPeriod = await apollo
                .mutate({
                    mutation:
                        SpecificExclusionPeriods.Mutations
                            .DeleteSpecificExclusionPeriod,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: id,
                    },
                })
                .then(({ data }) => data?.deleteSpecificExclusionPeriod)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return deletedSpecificExlcusionPeriod;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}

module.exports = SpecificExclusionPeriodsComponent;
