<template lang="pug">
  .upload-images
    .row
      .col-12
        file-input(
          :is-multiple="true"
          :file-input-changed="changeImages"
          accepted-file-types="image/*"
          :is-plain="true"
        )
    .row
      .col.mt-2(v-for="imageToUpload of imagesToUpload")
        .row
          .col-6
            h5 {{ imageToUpload.name }}
          .col-6
            .float-right
              a.cursor-pointer(@click="imagesToUpload.splice(imagesToUpload.indexOf(imageToUpload), 1)")
                font-awesome-icon(:icon="['fas', 'times']" size="xl")
        .row
          .col-12
            img.img-fluid(:src="imageToUpload.dataUrl")

    .row
      .col-12
        button.button.button-primary.button-tdays.w-100(type='button' @click="uploadImages" :disabled="imagesToUpload.length == 0" :class="{ 'disabled': imagesToUpload.length == 0 }")
          | hochladen

</template>

<script>
import EventBus from "@/views/event-bus";
import { NotificationTypesEnum } from "@/lib/enums/notificationTypes/NotificationTypesEnum.ts";
import { ImagesComponent } from "@/lib/components/images/ImagesComponent";
import { UserGroupsComponent } from "@/lib/components/userGroups/UserGroupsComponent";
import { NotificationsComponent } from "@/lib/components/notifications/NotificationsComponent";
import FileInput from "@/views/fileInput/FileInput.vue";

export default {
    name: "upload-images",
    components: { FileInput },
    props: {
        hostId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            imagesToUpload: [],
        };
    },
    methods: {
        async changeImages(images) {
            try {
                for (const image of images) {
                    const _image = this.imagesToUpload.find(
                        (i) => i.name == image.name
                    );
                    if (!_image) {
                        this.imagesToUpload.push(image);
                        continue;
                    }
                    this.imagesToUpload[this.imagesToUpload.indexOf(image)] =
                        image;
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async uploadImages() {
            try {
                if (this.imagesToUpload.length == 0) {
                    return this.$alert(
                        "Bitte wählen Sie zuerst Bilder für den Upload aus."
                    );
                } else if (this.imagesToUpload.length == 1) {
                    const confirmed = await this.$confirm(
                        "Sie haben nur ein Bild den Upload ausgewählt, sind Sie sicher dass sie nur ein einziges Bild hochladen möchten statt mehrere gleichzeitig?"
                    )
                        .then()
                        .catch((e) => e);
                    if (!confirmed) {
                        return;
                    }
                }
                let size = 0;
                this.imagesToUpload.forEach((image) => (size += image.size));
                size = size / 1000 / 1000;
                if (size > 100) {
                    return this.$alert(
                        `Die Daten überschreiten die Maximalgröße von 100mb pro Upload, bitte teilen Sie die Bilder auf. Aktuelle Größe: ${parseFloat(size).replace(".", ",")}mb.`
                    );
                }
                debugger;
                EventBus.$emit("changeLoadingState", true);
                const imagesComponent = new ImagesComponent();
                const uploadedImages = await imagesComponent.uploadImages(
                    this.$apollo,
                    this.hostId,
                    this.imagesToUpload
                );
                if (!uploadedImages) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Die Bilder konnten nicht hochgeladen werden."
                    );
                }
                if (!uploadedImages.IsSuccess) {
                    EventBus.$emit("changeLoadingState", false);
                    this.$alert(uploadedImages.Message);
                }
                const userGroupsComponent = new UserGroupsComponent();
                let userGroup =
                    await userGroupsComponent.getUserGroupByIndicator(
                        this.$apollo,
                        "images"
                    );
                if (!userGroup) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Keine zu addressierenden touriDat-Benutzer gefunden. Ihre Bilder wurden trotzdessen erfolgreich hochgeladen."
                    );
                }
                let notification = {
                    Title: `${this.imagesToUpload.length} ${
                        this.imagesToUpload.length == 1 ? "Bild" : "Bilder"
                    } hochgeladen.`,
                    UserGroupId: userGroup.Id,
                    HostId: this.hostId,
                    CreatedBy: this.$session.get("userId"),
                    NotificationTypeId: NotificationTypesEnum.ProductManager,
                };
                const notificationsComponent = new NotificationsComponent();
                let savedNotification =
                    await notificationsComponent.saveNotification(
                        this.$apollo,
                        notification
                    );
                if (!savedNotification) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Die Benachrichtigung für das touriDat-Service Team konnte nicht erstellt werden."
                    );
                }
                if (!savedNotification.IsSuccess) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(savedNotification.Message);
                }
                userGroup = await userGroupsComponent.getUserGroupByIndicator(
                    this.$apollo,
                    "büroleitung"
                );
                if (!userGroup) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Keine zu addressierenden touriDat-Benutzer gefunden. Ihre Bilder wurden trotzdessen erfolgreich hochgeladen."
                    );
                }
                notification = {
                    Title: `${this.imagesToUpload.length} ${
                        this.imagesToUpload.length == 1 ? "Bild" : "Bilder"
                    } hochgeladen.`,
                    UserGroupId: userGroup.Id,
                    HostId: this.hostId,
                    CreatedBy: this.$session.get("userId"),
                    NotificationTypeId: NotificationTypesEnum.ProductManager,
                };
                savedNotification =
                    await notificationsComponent.saveNotification(
                        this.$apollo,
                        notification
                    );
                if (!savedNotification) {
                    EventBus.$emit("changeLoadingState", false);
                    return this.$alert(
                        "Die Benachrichtigung für das touriDat-Team konnte nicht erstellt werden"
                    );
                }
                if (!savedNotification.IsSuccess) {
                    EventBus.$emit("changeLoadingState", false);
                    this.$alert(savedNotification.Message);
                }
                EventBus.$emit("changeLoadingState", false);
                await this.$alert(
                    "Ihre Bilder wurden erfolgreich an das touriDat Service-Team übermittelt und werden zeitnah bearbeitet."
                );
                this.imagesToUpload = [];
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
