<template lang="pug">
.table-responsive
  table.table.table-bordered
    thead
      tr
        th {{ $t('blockingDates.description') }}
        th {{ $t('blockingDates.period') }}
        th {{ $t('blockingDates.createdAt') }}
        th {{ $t('blockingDates.creator') }}
        th
    tbody
      specific-exclusion-periods-table-row(
        v-for="specificExclusionPeriod in specificExclusionPeriods"
        :specific-exclusion-period="specificExclusionPeriod"
        :load-specific-exclusion-periods="loadSpecificExclusionPeriods"
        :key="specificExclusionPeriod.Id"
      )

</template>

<script>
import SpecificExclusionPeriodsTableRow from "@/views/specificExclusionPeriods/SpecificExclusionPeriodsTableRow.vue";

export default {
    name: "SpecificExclusionPeriodsTable",
    components: { SpecificExclusionPeriodsTableRow },
    props: {
        specificExclusionPeriods: {
            type: Array,
            required: true,
        },
        loadSpecificExclusionPeriods: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style scoped></style>
