import gql from "graphql-tag";

export const SpecificExclusionPeriods = {
    Queries: {
        SpecificExclusionPeriods: gql`
        query specificExclusionPeriods($productId: ID, $productDraftId: ID, $hostId: ID) {
            specificExclusionPeriods(productId: $productId, productDraftId: $productDraftId, hostId: $hostId) {
                Id
                FromDate
                ToDate
                ProductId
                ProductDraftId
                Description
                IsNotAnnuallyRepeated
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                SpecificExclusionPeriodExceptionId
                SpecificExclusionPeriodExceptionStayDays
                HostId
            }
        }`,
    },
    Mutations: {
        SaveSpecificExclusionPeriod: gql`
        mutation saveSpecificExclusionPeriod($specificExclusionPeriod: SpecificExclusionPeriodsInput) {
            saveSpecificExclusionPeriod(specificExclusionPeriod: $specificExclusionPeriod) {
                IsSuccess
                Message
            }
        }`,
        DeleteSpecificExclusionPeriod: gql`
        mutation deleteSpecificExclusionPeriod($id: ID) {
            deleteSpecificExclusionPeriod(id: $id) {
                IsSuccess
                Message
            }
        }`,
    },
};
