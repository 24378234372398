import { UserGroups } from "@/lib/graphql/userGroups/UserGroups.ts";

export class UserGroupsComponent {
    async getUserGroupByIndicator(apollo, indicator) {
        try {
            if (!apollo && !indicator) {
                return undefined;
            }
            const userGroup = await apollo
                .query({
                    query: UserGroups.Queries.UserGroupByIndicator,
                    variables: {
                        indicator: indicator,
                    },
                })
                .then(({ data }) => data?.userGroupByIndicator)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return userGroup;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
