<template lang="pug">
  #app
    .text-center(style="padding-top: 400px;" v-if="dashboardMaintenance && dashboardMaintenance.IsActive")
      h1 Wir befinden uns derzeit im Wartungsmodus und sind in Kürze wieder für Sie da. 👷‍♀️
    template(v-else)
      tD-loading-overlay(v-if="isLoading" :progress="progress")
      div(v-if="$route.meta.isLogin")
        Login
      div(v-else-if="$route.meta.isResetPassword")
        ResetPassword
      div(v-else-if="$route.meta.isInitialContact")
        InitialContact

      template(v-else)
        header#globalhead(v-if="$session.exists()")
          .container-fluid
            .row
              .col-12.py-2
                .globalhead-collapse-icon.d-inline-block.d-lg-none.cursor-pointer(data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false")
                  i.txt-white
                    font-awesome-icon(:icon="['fas', 'bars']")

                a(href="/home").d-none.d-lg-inline-block.pt-2
                  img(src="https://td-cloud.s3.eu-central-1.amazonaws.com/Images/Logos/touridat/branding/white/touridat-branding-white-50x50.png")

                .float-right#headerlogout
                  .choose-host
                    host-select

                  img.align-middle(:src="`${publicPath}images/flag_ger.jpg`", v-on:click="changeLanguage('de')" style="cursor: pointer;").mr-3
                  img.align-middle(:src="`${publicPath}images/flag_gb.jpg`", v-on:click="changeLanguage('en')"  style="cursor: pointer;")

                  NotificationButton

                  .button-help-modal.txt-white(@click="showHelpModal")
                    i
                      font-awesome-icon(:icon="['fas', 'info-circle']")
                  a.button.text-white(v-if="$session.exists()" type="button" style="color:#fff" @click="logout") {{$t("login.button.logout")}}
                  router-link.button.text-white(v-else to="/login", style="color:#fff") {{$t("login.button.login")}}
        #content
          .container-fluid
            .row
              #sidebar-wrap.col-12.col-lg-3.border-right
                NavSidebar
              .col-12.col-lg-9
                router-view.mb-3(:key="$route.fullPath")

                button#contact-button.d-block.d-lg-none.button.button-primary.button-tdays.button-icon(@click="showContactModal")
                  font-awesome-icon(:icon="['fas', 'address-card']")
</template>

<script>
import ContactModal from "./modals/contact-modal.vue";
import NavSidebar from "./partials/nav.vue";
import HelpModal from "./modals/help-modal.vue";
import EventBus from "./../views/event-bus";
import NotificationButton from "../views/partials/notifications/NotificationButton.vue";
import { Maintenances } from "@/lib/graphql/maintenances/Maintenances.ts";
import PermissionsComponent from "@/lib/components/permissions/PermissionsComponent";
import VueSimpleAlert from "vue-simple-alert";

export default {
    data() {
        return {
            availableHosts: [],
            isLoading: false,
            progress: undefined,
            publicPath: process.env.BASE_URL,

            dashboardMaintenance: undefined,
        };
    },
    components: {
        NavSidebar,
        NotificationButton,
    },
    async mounted() {
        try {
            this.$notification.requestPermission();
            this.changeLanguage(navigator.language);
            EventBus.$on(
                "changeLoadingState",
                function (param) {
                    if (
                        Object.prototype.toString.call(param) ==
                            "[object Object]" &&
                        param.isLoading &&
                        param.progress
                    ) {
                        this.isLoading = param.isLoading;
                        this.progress = param.progress;
                    } else if (typeof param == "boolean") {
                        this.progress = undefined;
                        this.isLoading = param;
                    }
                }.bind(this)
            );
            EventBus.$on(
                "updatePermissions",
                function () {
                    this.loadPermissions();
                }.bind(this)
            );
            this.loadPermissions();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        logout() {
            try {
                this.$session.destroy();
                this.$router.push({ name: "Login" });
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        showContactModal() {
            this.$modal.show(
                ContactModal,
                {},
                {
                    width: "350",
                    height: "300",
                }
            );
        },
        showHelpModal() {
            this.$modal.show(
                HelpModal,
                {
                    viewDescription: this.$route.name,
                },
                {
                    scrollable: true,
                    resizable: true,
                    height: "auto",
                    width: "80%",
                }
            );
        },
        changeLanguage(lang) {
            try {
                this.$i18n.locale = lang;
                this.$session.set("lang", lang);
                VueSimpleAlert.globalOptions.cancelButtonText =
                    this.$t("buttons.cancel");
            } catch (e) {
                this.$session.set("lang", "de");
            }
        },
        async loadPermissions() {
            try {
                if (!this.$session.exists() || !this.$session.get("userId")) {
                    return;
                }
                const permissionsComponent = new PermissionsComponent();
                const permissions = await permissionsComponent.getPermissions(
                    this.$apollo,
                    this.$session.get("userId")
                );
                if (!permissions) {
                    return this.$alert(
                        "Es konnten keine Benutzerrechte geladen werden."
                    );
                }
                this.$session.set("permissions", permissions);
                EventBus.$emit("updateNav");
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    apollo: {
        dashboardMaintenance: {
            query: Maintenances.Queries.DashboardMaintenance,
            fetchPolicy: "no-cache",
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/styles/base.scss";
</style>
