import {IBAN} from "ibankit";

const ibantools = require('ibantools');

export class IbanComponent {
    isIBANValid(iban) {
        try {
            if (!iban) {
                return false;
            }
            const isIBANValid = ibantools.isValidIBAN(iban);
            return isIBANValid;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    isBICValid(bic) {
        try {
            if (!bic) {
                return false;
            }
            const isBICValid = ibantools.isValidBIC(bic);
            return isBICValid;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    getCensoredIban(iban) {
        try {
            if (!iban) {
                return undefined;
            }
            iban = IBAN.printFormat(iban);
            const splits = iban.split(" ");

            let _iban = "";
            for (const split of splits) {
                if (splits.indexOf(split) == splits.length - 1) {
                    _iban += "····";
                    continue;
                }
                _iban += split;
            }
            return _iban;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
