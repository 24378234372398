<template lang="pug">
.step-bar
  .step-progress
    .value(:style="`--progress:${progressValue};`")
  .steps
    a.step.d-flex.flex-wrap.align-items-center.justify-content-center.disabled(v-for="step in steps" :class="{ 'active-step': step === activeStep, 'done-step': step.isDone, 'locked-step': step.isLocked }" @click="setStep(step.step)")
      .value.h2.m-0.d-flex.flex-wrap.align-items-center.justify-content-center
        font-awesome-icon(icon="check" v-if="step.isDone")
        font-awesome-icon(icon="lock" v-else-if="step.isLocked")
        template(v-else) {{step.step}}
      .description.small.w-100
        | {{step.label}}
        template(v-if="!step.required") &nbsp;(optional)

</template>
<script>
export default {
    name: "StepBar",
    props: {
        setStep: {
            type: Function,
            required: true,
        },
        currentStep: {
            type: Number,
            default: 1,
            validator(value) {
                return [1, 2, 3, 4].includes(value);
            },
        },
        steps: {
            type: Array,
            validator(steps) {
                return steps.every(
                    (step) => typeof step === "object" && !Array.isArray(step)
                );
            },
            required: true,
        },
    },
    computed: {
        progressValue() {
            try {
                const maxStep =
                    [...this.steps].sort((a, b) => a.step - b.step)?.pop()
                        ?.step || 1;
                const pValue = (this.currentStep - 1) / maxStep;

                return pValue + 0.3 * pValue;
            } catch (e) {
                console.error(e);
                return 0;
            }
        },
        activeStep() {
            try {
                return this.steps[this.currentStep - 1];
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>
