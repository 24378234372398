<template lang="pug">
  .file-input
    .file-input-wrapper.border(
      :class="{ 'dragging': isDragging }"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    )
      input.custom-file-input#custom-file-input(
        ref="file"
        type="file"
        :accept="acceptedFileTypes"
        :multiple="isMultiple"
        @change="change"
      )

      template(v-if="!isPlain")
        ul.files-list(v-if="files.length > 0")
          file-input-list-item(
            v-for="file in files"
            :file="file"
            :remove-file="removeFile"
            :key="files.indexOf(file)"
          )
          li.files-list-item(v-if="isMultiple")
            label.badge.badge-secondary.font-weight-normal.p-2.cursor-pointer(for="custom-file-input")
              span hinzufügen
              font-awesome-icon.ml-2(:icon="['fas', 'plus']")

      label.form-label.cursor-pointer.w-100.d-table(for="custom-file-input" v-if="isPlain || files.length == 0")
        .text-center.d-table-cell.align-middle(:style="'height: ' + height + 'px;'")
          span(v-if="isDragging") Dateien hier ablegen
          span(v-else) Dateien hier ablegen oder klicken

</template>

<script>
import FileInputListItem from "@/views/fileInput/FileInputListItem.vue";

export default {
    name: "FileInput",
    components: { FileInputListItem },
    props: {
        isMultiple: {
            type: Boolean,
            required: true,
        },
        fileInputChanged: {
            type: Function,
            required: true,
        },
        acceptedFileTypes: {
            type: String,
            required: false,
            default: "*",
        },
        height: {
            type: Number,
            required: false,
            default: 100,
        },
        isPlain: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            files: [],
            isDragging: false,
        };
    },
    methods: {
        async change() {
            try {
                const files = this.$refs.file.files || [];
                for (const file of files) {
                    if (this.files.find((f) => f.name == file.name)) {
                        continue;
                    }
                    const dataUrl = await this.fileToBase64(file);
                    this.files.push({
                        name: file.name,
                        dataUrl: dataUrl,
                        lastModified: file.lastModified,
                        size: file.size,
                        type: file.type,
                        webkitRelativePath: file.webkitRelativePath,
                    });
                }
                this.fileInputChanged(this.files);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        dragover(e) {
            try {
                if (this.files.length > 0) {
                    return;
                }
                e.preventDefault();
                this.isDragging = true;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        dragleave() {
            try {
                if (this.files.length > 0) {
                    return;
                }
                this.isDragging = false;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async drop(e) {
            try {
                e.preventDefault();
                const files = e.dataTransfer?.files || [];
                for (const file of files) {
                    if (this.files.find((f) => f.name == file.name)) {
                        continue;
                    }
                    const dataUrl = await this.fileToBase64(file);
                    this.files.push({
                        name: file.name,
                        dataUrl: dataUrl,
                        lastModified: file.lastModified,
                        size: file.size,
                        type: file.type,
                        webkitRelativePath: file.webkitRelativePath,
                    });
                }
                this.isDragging = false;
                this.fileInputChanged(this.files);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async fileToBase64(file) {
            try {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.onload = (ev) => {
                        resolve(ev.target.result);
                    };
                    reader.readAsDataURL(file);
                });
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
        removeFile(file) {
            try {
                this.files.splice(this.files.indexOf(file), 1);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped>
input[type="file"] {
    display: none;
}

.dragging {
    border: 1px solid #777777 !important;
}

ul {
    margin: 0;
    padding: 0;
}

ul.files-list {
    position: relative;
    height: auto;
    width: 100%;
    list-style: none;
    overflow: auto;
}

.files-list li.files-list-item {
    width: auto;
    float: left;
    margin-left: 6px;
    margin-top: 6px;
}
</style>
