<template lang="pug">
  .modal-wrap.p-3.mt-3
    .modal-close-wrap
      i.modal-close-button(@click="close")
        font-awesome-icon(:icon="['fas', 'times']")
    .container-fluid#host-account-information-modal
      .row
        .col-12
          .modal-headline.pb-2.mb-3.border-bottom Ihre Bankverbindung
      .row
        .col-12
          .form-group
            label.form-label(for="Iban") IBAN
            input#Iban.form-control(type="text" v-model="iban")
        .col-12
          .form-group
            label.form-label(for="AccountOwner") Kontoinhaber(in)
            input#AccountOwner.form-control(type="text" v-model="accountOwner")
        .col-12.mt-3
          button.button.button-primary.button-tdays.float-right(@click="saveAccountInformation")
            | speichern

</template>

<script>
import { IBAN } from "ibankit";
import EventBus from "../../event-bus";
import { NotificationTypesEnum } from "@/lib/enums/notificationTypes/NotificationTypesEnum.ts";
import { NotificationsComponent } from "@/lib/components/notifications/NotificationsComponent";
import { UserGroupsComponent } from "@/lib/components/userGroups/UserGroupsComponent";

export default {
    name: "HostAccountInformationModal",
    props: {
        HostId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            iban: "",
            accountOwner: "",
        };
    },
    methods: {
        close() {
            try {
                this.$emit("close");
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async saveAccountInformation() {
            try {
                if (!this.iban) {
                    return this.$alert("Sie haben keine Iban vergeben.");
                }
                if (!IBAN.isValid(this.iban)) {
                    return this.$alert(
                        "Die von Ihnen vergebene IBAN ist nicht gültig."
                    );
                }
                if (!this.accountOwner) {
                    return this.$alert(
                        "Sie haben keine(n) Kontoinhaber(in) vergeben."
                    );
                }
                const confirmed = await this.$confirm(
                    this.$t(
                        "constants.confirm.saveHostAccountInformation"
                    ).toString(),
                    this.$t("constants.confirm.globalTitle").toString()
                )
                    .then()
                    .catch((e) => e);
                if (!confirmed) {
                    return;
                }
                const userGroupsComponent = new UserGroupsComponent();
                EventBus.$emit("changeLoadingState", true);
                const userGroup =
                    await userGroupsComponent.getUserGroupByIndicator(
                        this.$apollo,
                        "büroleitung"
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!userGroup) {
                    return this.$alert(
                        "Keine zu addressierenden touriDat-Benutzer gefunden. Ihre Bilder wurden trotzdessen erfolgreich hochgeladen."
                    );
                }
                const notification = {
                    Title: `Geänderte Zahlungsinformationen von Gastgeber ${this.HostId}`,
                    Message: `IBAN: ${this.iban}\nKontoinhaber(in): ${this.accountOwner}`,
                    UserGroupId: userGroup.Id,
                    HostId: this.HostId,
                    CreatedBy: this.$session.get("userId"),
                    NotificationTypeId: NotificationTypesEnum.ProductManager,
                };
                const notificationsComponent = new NotificationsComponent();
                EventBus.$emit("changeLoadingState", true);
                const savedNotification =
                    await notificationsComponent.saveNotification(
                        this.$apollo,
                        notification
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!savedNotification) {
                    return this.$alert(
                        "Die Benachrichtigung der geänderten Zahlungsinformationen konnte nicht an den touriDat-Service übermittelt werden."
                    );
                }
                this.$alert(
                    "Die Benachrichtigung der geänderten Zahlungsinformationen wurde erfolgreich an den touriDat-Service übermittelt."
                );
                this.close();
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
