<template lang="pug">
  .wrapper
    tD-loading-overlay(v-if="$apollo.loading")
    .row
      .col-12
        h2 {{$t("account.documents.headline")}}
      .col-12
        .table-responsive
          table.table.table-bordered.text-center.w-100.d-table
            thead
              tr
                th(scope="col") {{$t("account.documents.table.documentNumber")}}
                th(scope="col") {{$t("account.documents.table.name")}}
                th(scope="col") {{$t("account.documents.table.date")}}
                th(scope="col") #
            tbody
              tr(v-for="hostSystemFile in hostSystemFiles")
                td {{hostSystemFile.Id}}
                td {{hostSystemFile.FileName}}
                td {{hostSystemFile.PublishedAt | formatDate}}
                td
                  button.button.button-primary.button-tdays(@click="openSignedObjectUrl(hostSystemFile.FilePath.replace('https://td-cloud.s3.eu-central-1.amazonaws.com/', ''))" v-if="hostSystemFile.FilePath.includes('https://td-cloud.s3.eu-central-1.amazonaws.com/')") {{$t("account.documents.table.showPdf")}}
                  button.button.button-primary.button-tdays(@click="openSignedObjectUrl(`${getHostSystemFileFileType(hostSystemFile.FileType)}/${hostSystemFile.HostId}/${hostSystemFile.FileName}`)" v-else) {{$t("account.documents.table.showPdf")}}

    pagination(v-if="!$apollo.loading" :isDocument="true" :DataCount="hostSystemFilesCount" :PageSize="this.pageSize" :DataCountText="'account.documents.count'")

</template>

<script>
import EventBus from "@/views/event-bus";
import moment from "moment";
import { SystemFiles } from "@/lib/graphql/systemFiles/SystemFiles.ts";
import AwsS3Component from "@/lib/components/awsS3/AwsS3Component";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Documents",
    props: {
        slug: String,
    },
    data() {
        return {
            hostSystemFiles: [],
            hostSystemFilesCount: 0,
            hostId: this.$session.get("hostId"),
            moment: moment,
            pageSize: 10,
            offset: 0,
        };
    },
    mounted() {
        EventBus.$on(
            "changeHostId",
            function (hostId) {
                this.hostId = hostId;
            }.bind(this)
        );
        EventBus.$on(
            "changeDocumentOffset",
            function (offset) {
                this.offset = offset;
            }.bind(this)
        );
        EventBus.$on(
            "changeDocumentPageSize",
            function (pageSize) {
                this.pageSize = parseFloat(pageSize);
            }.bind(this)
        );
    },
    methods: {
        async openSignedObjectUrl(key) {
            try {
                if (!key) {
                    return this.$alert(
                        "Kein Key zum generieren der Url gefunden."
                    );
                }
                const awsS3Component = new AwsS3Component();
                EventBus.$emit("changeLoadingState", true);
                const signedObjectUrl = await awsS3Component.getSignedObjectUrl(
                    this.$apollo,
                    key
                );
                EventBus.$emit("changeLoadingState", false);
                if (!signedObjectUrl) {
                    return this.$alert("Es konnte keine Url generiert werden.");
                }
                window.open(signedObjectUrl, "_blank").focus();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        getHostSystemFileFileType(fileType) {
            try {
                if (!fileType) {
                    return undefined;
                }
                if (fileType.toLowerCase() == "auftrag") {
                    return "contracts";
                }
                return fileType;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
    apollo: {
        hostSystemFiles: {
            query: SystemFiles.Queries.HostSystemFiles,
            loadingKey: "loading",
            variables() {
                return {
                    hostId: this.hostId,
                    status: "sichtbar",
                    offset: this.offset,
                    limit: this.pageSize,
                };
            },
        },
        hostSystemFilesCount: {
            query: SystemFiles.Queries.HostSystemFilesCount,
            loadingKey: "loading",
            variables() {
                return {
                    hostId: this.hostId,
                    status: "sichtbar",
                };
            },
            result({ data }) {
                if (!data || data.hostSystemFilesCount == undefined) {
                    return this.$alert(
                        "Es konnten keine Dokumente gefunden werden!",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                EventBus.$emit(
                    "changeDocumentDataCount",
                    data.hostSystemFilesCount
                );
            },
        },
    },
};
</script>

<style scoped></style>
