import { Images } from "@/lib/graphql/images/Images.ts";

export class ImagesComponent {
    async uploadImages(apollo, hostId, files) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!hostId) {
                return {
                    IsSuccess: false,
                    Message: "Kein Gastgeber gefunden.",
                };
            }
            if (!files || !Array.isArray(files) || !files.length) {
                return {
                    IsSuccess: false,
                    Message: "Keine Bilder gefunden",
                };
            }
            const uploadedImages = apollo
                .mutate({
                    mutation: Images.Mutations.UploadImages,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostId: hostId,
                        files: files,
                    },
                })
                .then(({ data }) => data?.uploadImages)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            return uploadedImages;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
