<template lang="pug">
  .wrapper(v-if="host")
    .row
      .col-12
        h2 {{$t("account.data.headline")}}
    .row.mt-2
      .col-12
        .section-headline {{$t("account.data.hostHeadline")}}
    .row
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("account.data.hostNumber")}}
          input.form-control.custom-control(type="text", disabled="true", :value="host.Id")
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("account.data.hostProvision")}}
          input.form-control.custom-control(type="text", disabled="true", :value="host.ProvisionFP | formatPercentage")
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("account.data.hostHandlingFee")}}
          input.form-control.custom-control(type="text", disabled="true", :value="host.Verkaufsgebuehren | formatEuro")
    .row
      .col-12.col-md-4.mt-2
        .row
          .col-12
            .section-headline {{$t("account.data.hostAddressHeadline")}}
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostTypeHeadline")}}
              select.custom-select
                option {{$t("account.data.hostTypeHotel")}}
                option {{$t("account.data.hostTypeApartment")}}
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostName")}}
              input.form-control.custom-control(type="text", v-model="host.Name")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostContactPerson")}}
              input.form-control.custom-control(type="text", v-model="host.PAdresse3")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostAddress")}}
              input.form-control.custom-control(type="text", v-model="host.PAdresse4")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostZip")}}
              input.form-control.custom-control(type="text", v-model="host.PPLZ")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostCity")}}
              input.form-control.custom-control(type="text", v-model="host.PORT")
          .col-12
            .form-group
              label.form-label.w-100 {{$t("account.data.hostCountry")}}
                select.custom-select
                  option(selected="host.PLAND == 'DEU'") {{$t("constants.countries.de")}}
                  option {{$t("constants.countries..be")}}
                  option {{$t("constants.countries.fr")}}
                  option {{$t("constants.countries.it")}}
                  option {{$t("constants.countries.lu")}}
                  option {{$t("constants.countries.nl")}}
                  option {{$t("constants.countries.at")}}
                  option {{$t("constants.countries.pl")}}
                  option {{$t("constants.countries.ch")}}
      .col-12.col-md-4.mt-2
        .row
          .col-12
            .section-headline {{$t("account.data.hostInvoiceHeadline")}}
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostInvoiceName")}}
              input.form-control.custom-control(type="text", v-model="host.RAdresse1")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostInvoiceName2")}}
              input.form-control.custom-control(type="text", v-model="host.RAdresse2")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostInvoiceCEO")}}
              input.form-control.custom-control(type="text", v-model="host.RAdresse3")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostInvoiceAddress")}}
              input.form-control.custom-control(type="text", v-model="host.RAdresse4")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostInvoiceZip")}}
              input.form-control.custom-control(type="text", v-model="host.RPLZ")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostInvoiceCity")}}
              input.form-control.custom-control(type="text", v-model="host.RORT")
          .col-12
            .form-group
              label.form-label.w-100 Land
              select.custom-select(v-model="host.RLAND")
                option(selected="host.RLAND == 'DEU'") {{$t("constants.countries.de")}}
                option {{$t("constants.countries.be")}}
                option {{$t("constants.countries.fr")}}
                option {{$t("constants.countries.it")}}
                option {{$t("constants.countries.lu")}}
                option {{$t("constants.countries.nl")}}
                option {{$t("constants.countries.at")}}
                option {{$t("constants.countries.pl")}}
                option {{$t("constants.countries.ch")}}
      .col-12.col-md-4.mt-2
        .row
          .col-12
            .section-headline {{$t("account.data.hostContactHeadline")}}
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostContactPhone1")}}
              input.form-control.custom-control(type="text", v-model="host.TELEFON1")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostContactPhone2")}}
              input.form-control.custom-control(type="text", v-model="host.TELEFON2")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostContactTelefax")}}
              input.form-control.custom-control(type="text", v-model="host.TELEFAX1")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostContactPersonTouriDat")}}
              input.form-control.custom-control(type="text", v-model="host.Ansprechpartner")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostContactPersonTouriDatEmail")}}
              input.form-control.custom-control(type="text", v-model="host.EMAIL2")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostContactEmailGuest")}}
              input.form-control.custom-control(type="text", v-model="host.EMAIL1")
          .col-12
            .form-group
              label.form-label {{$t("account.data.hostContactHomepage")}}
              input.form-control.custom-control(type="text", v-model="host.Internetadresse2")
        .row
          .col-12
            button.button.button-primary.button-tdays.float-right(@click="saveHostData")
              | {{$t("constants.buttons.submitChanges")}}

      .col-12.mt-3
        .panel.panel-default
          .panel-heading Bankverbindung
          .panel-body
            .row
              .col-12
                label.form-label(for="Iban")
                input#Iban.form-control(type="text" v-model="iban" disabled)
              .col-12
                label.form-label(for="AccountOwner")
                input#AccountOwner.form-control(type="text" v-model="host.AccountOwner" disabled)

              .col-12.mt-3
                button.button.button-primary.button-tdays.float-right(@click="editAccountInformation")
                  | Bankverbindung editieren


</template>

<script>
import EventBus from "../../../views/event-bus";
import HostAccountInformationModal from "../../modals/hostAccountInformation/HostAccountInformationModal";
import { UserGroupsComponent } from "@/lib/components/userGroups/UserGroupsComponent";
import { HostsComponent } from "@/lib/components/hosts/HostsComponent";
import { IbanComponent } from "@/lib/components/iban/IbanComponent.ts";
import { NotificationsComponent } from "@/lib/components/notifications/NotificationsComponent";
import { NotificationTypesEnum } from "@/lib/enums/notificationTypes/NotificationTypesEnum.ts";

export default {
    name: "account-data",
    data() {
        return {
            hostId: this.$session.get("hostId"),
            host: undefined,
            backupHost: undefined,
        };
    },
    mounted() {
        try {
            EventBus.$on(
                "changeHostId",
                function (hostId) {
                    this.hostId = hostId;
                }.bind(this)
            );
            this.loadHost();
        } catch (e) {
            console.error(e);
        }
    },
    methods: {
        async loadHost() {
            try {
                const hostsComponent = new HostsComponent();
                EventBus.$emit("changeLoadingState", true);
                const host = await hostsComponent.getHost(
                    this.$apollo,
                    this.hostId
                );
                EventBus.$emit("changeLoadingState", false);
                if (!host) {
                    return;
                }
                this.host = host;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async saveHostData() {
            try {
                const confirmed = await this.$confirm(
                    this.$t(
                        "constants.messages.saveChangedHostData"
                    ).toString(),
                    this.$t("constants.messages.questionTitle").toString()
                )
                    .then()
                    .catch((e) => e);
                if (!confirmed) {
                    return;
                }
                let message = "";
                for (const key of Object.keys(this.host)) {
                    if (typeof this.host[key] !== "string") {
                        continue;
                    }
                    if (
                        this.backupHost[key]?.toLowerCase()?.trim() ==
                        this.host[key]?.toLowerCase()?.trim()
                    ) {
                        continue;
                    }
                    if (message.length) {
                        message += "\n";
                    }
                    message += `${key}: ${this.host[key]}`;
                }
                if (!message) {
                    return;
                }
                const userGroupsComponent = new UserGroupsComponent();
                EventBus.$emit("changeLoadingState", true);
                const userGroup =
                    await userGroupsComponent.getUserGroupByIndicator(
                        this.$apollo,
                        "büroleitung"
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!userGroup) {
                    return this.$alert(
                        "Keine zu addressierenden touriDat-Benutzer gefunden."
                    );
                }
                const notification = {
                    Title: `Geänderte Informationen von Gastgeber ${this.HostId}`,
                    Message: message,
                    UserGroupId: userGroup.Id,
                    HostId: this.host.Id,
                    CreatedBy: this.$session.get("userId"),
                    NotificationTypeId: NotificationTypesEnum.ProductManager,
                };
                const notificationsComponent = new NotificationsComponent();
                EventBus.$emit("changeLoadingState", true);
                const savedNotification =
                    await notificationsComponent.saveNotification(
                        this.$apollo,
                        notification
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!savedNotification) {
                    return this.$alert(
                        "Die Benachrichtigung der geänderten Informationen konnte nicht an den touriDat-Service übermittelt werden."
                    );
                }
                this.$alert(
                    "Die Benachrichtigung der Informationen wurde erfolgreich an den touriDat-Service übermittelt."
                );
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        editAccountInformation() {
            try {
                this.$modal.show(
                    HostAccountInformationModal,
                    {
                        HostId: this.hostId,
                    },
                    {
                        width: "400",
                        height: "300",
                    }
                );
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        hostId() {
            try {
                this.loadHost();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        "host.Id"() {
            try {
                if (!this.host?.Id) {
                    return;
                }
                this.backupHost = JSON.parse(JSON.stringify(this.host));
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        iban() {
            try {
                if (!this.host || !this.host?.IBAN) {
                    return undefined;
                }
                const ibanComponent = new IbanComponent();
                const iban = ibanComponent.getCensoredIban(this.host.IBAN);
                return iban;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>

<style scoped></style>
