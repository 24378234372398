<template lang="pug">
tr
  td {{ specificExclusionPeriod.Description }}
  td {{ specificExclusionPeriod.FromDate | formatDate }} - {{ specificExclusionPeriod.ToDate | formatDate }}
  td {{ specificExclusionPeriod.CreatedAt | formatDate }}
  td: span(v-if="user") {{ user.FirstName }} {{ user.LastName }}
  td
    button.button.button-primary.button-tpics.w-100(@click="deleteSpecificExclusionPeriod")
      font-awesome-icon.mr-2(:icon="['fas', 'trash']")
      | {{ $t("buttons.delete") }}

</template>

<script>
import UsersComponent from "@/lib/components/users/UsersComponent";
import SpecificExclusionPeriodsComponent from "@/lib/components/specificExclusionPeriods/SpecificExclusionPeriodsComponent";
import EventBus from "@/views/event-bus";

export default {
    name: "SpecificExclusionPeriodsTableRow",
    props: {
        specificExclusionPeriod: {
            type: Object,
            required: true,
        },
        loadSpecificExclusionPeriods: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            user: undefined,
        };
    },
    async mounted() {
        try {
            this.loadUser();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadUser() {
            try {
                const usersComponent = new UsersComponent();
                const user = await usersComponent.getUser(
                    this.$apollo,
                    this.specificExclusionPeriod?.CreatedBy
                );
                if (!user) {
                    return;
                }
                this.user = user;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async deleteSpecificExclusionPeriod() {
            try {
                const confirmed = await this.$confirm(
                    this.$t(
                        "blockingDates.deleteSpecificExclusionPeriod.confirm"
                    ),
                    undefined,
                    undefined,
                    {}
                )
                    .then()
                    .catch((e) => e);
                if (!confirmed) {
                    return;
                }
                const specificExclusionPeriodsComponent =
                    new SpecificExclusionPeriodsComponent();
                EventBus.$emit("changeLoadingState", true);
                const deletedSpecificExclusionPeriod =
                    await specificExclusionPeriodsComponent.deleteSpecificExclusionPeriod(
                        this.$apollo,
                        this.specificExclusionPeriod?.Id
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!deletedSpecificExclusionPeriod) {
                    return this.$alert(
                        this.$t(
                            "blockingDates.deleteSpecificExclusionPeriod.error"
                        )
                    );
                }
                if (deletedSpecificExclusionPeriod.Message) {
                    this.$alert(deletedSpecificExclusionPeriod.Message);
                }
                if (deletedSpecificExclusionPeriod.IsSuccess) {
                    this.loadSpecificExclusionPeriods();
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
