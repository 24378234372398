<template lang="pug">
  .specific-exclusion-periods
    .row.mb-3
      .col-md-4.col-sm-12
        label.form-label {{ $t("blockingDates.description") }}
        input.form-control(v-model="specificExclusionPeriod.Description" :placeholder="$t('blockingDates.descriptionPlaceholder')")
      .col-md-4.col-sm-12
        label.form-label {{ $t("blockingDates.fromDate") }}
        input.form-control(type="date" :value="specificExclusionPeriod.FromDate | formatDatePickerValue" @change="specificExclusionPeriod.FromDate = ($event.target.value ? $event.target.value : null);")
      .col-md-4.col-sm-12
        label.form-label {{ $t("blockingDates.toDate") }}
        input.form-control(type="date" :value="specificExclusionPeriod.ToDate | formatDatePickerValue" @change="specificExclusionPeriod.ToDate = ($event.target.value ? $event.target.value : null);")

    .row(v-if="specificExclusionPeriods && Array.isArray(specificExclusionPeriods) && specificExclusionPeriods.length")
      .col-12
        specific-exclusion-periods-table(
          :specific-exclusion-periods="specificExclusionPeriods"
          :load-specific-exclusion-periods="loadSpecificExclusionPeriods"
        )

    .row
      .col-12
        .float-right
          button.button.button-primary.button-tdays(@click="saveSpecificExclusionPeriod")
            font-awesome-icon.mr-2(:icon="['fas', 'floppy-disk']")
            | {{ $t("buttons.save") }}

</template>

<script>
import { PermissionsEnum } from "@/lib/enums/permissions/PermissionsEnum";
import SpecificExclusionPeriodsComponent from "@/lib/components/specificExclusionPeriods/SpecificExclusionPeriodsComponent";
import EventBus from "@/views/event-bus";
import SpecificExclusionPeriodsTable from "@/views/specificExclusionPeriods/SpecificExclusionPeriodsTable.vue";

export default {
    name: "SpecificExclusionPeriods",
    components: { SpecificExclusionPeriodsTable },
    data() {
        return {
            specificExclusionPeriod: {
                CreatedBy: this.$session.get("userId"),
                HostId: this.$session.get("hostId"),
            },
            specificExclusionPeriods: [],
        };
    },
    beforeMount() {
        try {
            const permissions = this.$session.get("permissions");
            if (
                !permissions ||
                permissions.some(
                    (permission) =>
                        permission == PermissionsEnum.ExclusionPeriods
                )
            ) {
                return this.$router.push({ name: "Home" });
            }
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    mounted() {
        try {
            this.loadSpecificExclusionPeriods();
            EventBus.$on(
                "changeHostId",
                function (hostId) {
                    this.loadSpecificExclusionPeriods();
                    this.specificExclusionPeriod.HostId = hostId;
                }.bind(this)
            );
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadSpecificExclusionPeriods() {
            try {
                const specificExclusionPeriodsComponent =
                    new SpecificExclusionPeriodsComponent();
                EventBus.$emit("changeLoadingState", true);
                const specificExclusionPeriods =
                    await specificExclusionPeriodsComponent.getSpecificExclusionPeriods(
                        this.$apollo,
                        undefined,
                        undefined,
                        this.$session.get("hostId")
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!specificExclusionPeriods) {
                    return;
                }
                this.specificExclusionPeriods = specificExclusionPeriods;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async saveSpecificExclusionPeriod() {
            try {
                const specificExclusionPeriodsComponent =
                    new SpecificExclusionPeriodsComponent();
                EventBus.$emit("changeLoadingState", true);
                const savedSpecificExclusionPeriod =
                    await specificExclusionPeriodsComponent.saveSpecificExclusionPeriod(
                        this.$apollo,
                        this.specificExclusionPeriod
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!savedSpecificExclusionPeriod) {
                    return this.$alert(
                        this.$t(
                            "blockingDates.saveSpecificExclusionPeriod.error"
                        )
                    );
                }
                if (savedSpecificExclusionPeriod.Message) {
                    this.$alert(savedSpecificExclusionPeriod.Message);
                }
                if (savedSpecificExclusionPeriod.IsSuccess) {
                    this.loadSpecificExclusionPeriods();
                    this.specificExclusionPeriod = {
                        CreatedBy: this.$session.get("userId"),
                        HostId: this.$session.get("hostId"),
                    };
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
