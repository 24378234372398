<template lang="pug">
  .exclusion-periods.mt-3
    .container-fluid
      .row
        .col-12
          h2 {{ $t("blockingDates.headline") }}
      specific-exclusion-periods

</template>

<script>
import SpecificExclusionPeriods from "@/views/specificExclusionPeriods/SpecificExclusionPeriods.vue";

export default {
    name: "ExclusionPeriods",
    components: { SpecificExclusionPeriods },
};
</script>

<style scoped></style>
