<template lang="pug">
  li.files-list-item
    .badge.badge-secondary.font-weight-normal.p-2
      font-awesome-icon.mr-2(:icon="['fas', 'file-csv']" v-if="file.type == 'text/csv'")
      font-awesome-icon.mr-2(:icon="['fas', 'file-zipper']" v-else-if="file.type == 'application/zip'")
      font-awesome-icon.mr-2(:icon="['fas', 'file-pdf']" v-else-if="file.type == 'application/pdf'")
      font-awesome-icon.mr-2(:icon="['fas', 'file-image']" v-else-if="file.type.includes('image/')")
      font-awesome-icon.mr-2(:icon="['fas', 'file']" v-else)
      span {{ file.name }}
      span(v-if="size") &nbsp;{{ size }}
      font-awesome-icon.ml-2.cursor-pointer(icon='trash' @click="removeFile(file)")

</template>

<script>
export default {
    name: "FileInputListItem",
    props: {
        file: {
            type: Object,
            required: true,
        },
        removeFile: {
            type: Function,
            required: true,
        },
    },
    computed: {
        size() {
            try {
                if (this.file.size > 0 && this.file.size < 1000) {
                    return `(${this.file.size} Byte)`;
                } else if (this.file.size > 1000 && this.file.size < 1000000) {
                    return `(${parseFloat(
                        parseFloat(this.file.size / 1000).toFixed(0)
                    )} KB)`;
                } else if (this.file.size > 1000000) {
                    return `(${parseFloat(
                        parseFloat(this.file.size / 1000000).toFixed(1)
                    )
                        .toString()
                        .replace(".", ",")} MB)`;
                }
                return undefined;
            } catch (e) {
                console.error(e);
                return undefined;
            }
        },
    },
};
</script>

<style scoped></style>
